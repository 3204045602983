import '../Style/RemoveCard.css';
import { deleteCard } from '../Actions/Card';

export default function RemoveCard({cardId, setDeleteCard, setCard, useCase}){

    const output = (<div id='RemoveCard'>
                        <div>
                            <h1>Are you sure you want to remove the card?</h1>
                            {useCase=='Checkout' && (
                                <button className='Delete' onClick={()=>{deleteCard(cardId);setDeleteCard(false);setCard(0)}}>Yes</button>
                            )}
                            {useCase=='SignUp' && (
                                <button className='Delete' onClick={()=>{deleteCard(cardId);setDeleteCard(false);}}>Yes</button>
                            )}
                            <button className='Cancel' onClick={()=>setDeleteCard(false)}>No</button>
                        </div>
                    </div>)

    return output;

}