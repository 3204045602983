import '../Style/AuthStatus.css';
import {useState, useEffect, useContext} from 'react';
import {useLocation,useNavigate,useParams} from "react-router-dom";
import { authorizationStatus } from '../Actions/Card';
import { UserDataContext } from '../Data/Session';

export default function AuthStatus(){
    const location = useLocation();
    const {userData} = useContext(UserDataContext);
    const [result, setResult] = useState("Result Loading....");
    const navigate = useNavigate();
    const {useCase} = useParams();

    useEffect( ()=>{
        if(userData){ 
            const func = async ()=>{
                try{
                    const searchParams = new URLSearchParams(location.search);
                    const id = searchParams.get("id");
                    const response =  await authorizationStatus({id,userId:userData._id});
                    
                    if(response && response.code){
                        setResult((response.code==0)?"Card Authorization Successful!!":"Card Authorization Failed.");
                    }
                    else{
                        setResult("Card Authorization Failed.")
                    }
                }
                catch(error){
                    setResult("Card Authorization Failed.")
                    console.error("Card Authorization Error:",error)
                }
            }
            func();
        }
    },[location, userData])

    const output = (<div id='AuthStatus'>
                        <img src="/Images/Background1.png"></img>
                        <h1>{result}</h1>
                        <button onClick={()=>navigate((useCase=='c')?'/checkout':'/signUp3')} >{(useCase=='c')?'Back to Checkout':'Back to Sign Up'}</button>
                    </div>);

    return output;
}