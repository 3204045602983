import axios from 'axios';

const url = 'https://scheduleease.co.za/api/user/';

///////////////////////////// GET REQUEST /////////////////////////////////////

export const getUsers = async() => {
    try {
        const {data} = await axios.get(url);
        return data;
    } catch (error) {
        console.log(error)
    }
}

////////////////////////////// POST REQUEST ///////////////////////////////////
export const signIn = async(Data) => {
    try {
        const response = await axios.post(`${url}signIn`,Data);
        return response;
  
    } catch (error) {
       console.log(error);
    }
}

export const signUp1 = async(email) => { 
    
    try {
        const response = await axios.post(`${url}signUp1`,email);
        return response;
  
    } catch (error) {
       console.log(error);
    }
}

export const signUp2A = async(input) => { 
    
    try {
        const response = await axios.post(`${url}signUp2`,input);
        return response;
  
    } catch (error) {
       console.log(error);
    }
}

export const forgotPassword = async(Data) => {
    try {
        const response = await axios.post(`${url}forgotPassword`,Data);
        return response.data;
  
    } catch (error) {
       console.log(error);
       return {noEmail:true}
    }
}

export const resetPassword = async(code, Data) => {
    try {
        const response = await axios.post(`${url}resetPassword/${code}`,Data);
        return response.data;
  
    } catch (error) {
       console.log(error);
       return {successful:false};
    }
}

///////////////////////////////// UPDATE REQUEST /////////////////////////////////////////

export const updateUser = (id, updatedData) =>{
    try {
        const formData = new FormData();
        
        if(updatedData.createdDate!=null){
            formData.append('createdDate',String(updatedData.createdDate));
        }
        if(updatedData.location!=null){
            formData.append('location',JSON.stringify(updatedData.location));
        }
        if(updatedData.city!=null){
            formData.append('city',updatedData.city);
        }
        if(updatedData.name!=null){
            formData.append('name',updatedData.name);
        }
        if(updatedData.surname!=null){
            formData.append('surname',updatedData.surname);
        }
        if(updatedData.email!=null){
            formData.append('email',updatedData.email);
        }
        if(updatedData.imgFile!=null){
            formData.append('imgFile',updatedData.imgFile);
        }
        if(updatedData.number!=null){
            formData.append('number',updatedData.number);
        }
        if(updatedData.password!=null){
            formData.append('password',updatedData.password);
        }
        if(updatedData.promotions!=null){
            formData.append('promotions',String(updatedData.promotions));
        }
        if(updatedData.CardOption!=null){
            formData.append('CardOption',String(updatedData.CardOption));
        }
        if(updatedData.terms!=null){
            formData.append('terms',String(updatedData.terms));
        }
        if(updatedData.flagged!=null){
            formData.append('flagged',String(updatedData.flagged));
        }
        if(updatedData.sessionId!=null){
            formData.append('sessionId',updatedData.sessionId);
        }
        if(updatedData.previous!=null){
            const prevArray = updatedData.previous;
            if(prevArray.length>0){
                for(let i = 0;i<prevArray.length;i++){
                    formData.append('previous[]',prevArray[i]);
                }
            }
            else{
                formData.append('previous[]',[]);
            }
        }
        if(updatedData.upcoming!=null){
            const upArray = updatedData.upcoming;
            if(upArray.length>0){
                for(let i = 0;i<upArray.length;i++){
                    formData.append('upcoming[]',upArray[i]);
                }
            }
            else{
                formData.append('upcoming[]',[]);
            }
        }
        if(updatedData.saved!=null){
            const savedArray = updatedData.saved;
            if(savedArray.length>0){
                for(let i = 0;i<savedArray.length;i++){
                    formData.append('saved[]',savedArray[i]);
                }
            }
            else{
                formData.append('saved[]',[]);
            }
        }
        if(updatedData.Category1!=null){
            const Category1Array = updatedData.Category1;
            if(Category1Array.length>0){
                for(let i = 0;i<Category1Array.length;i++){
                    formData.append('Category1[]',Category1Array[i]);
                }
            }
            else{
                formData.append('Category1[]',[]);
            }
        }
        if(updatedData.Category2to4!=null){
            const Category2to4Array = updatedData.Category2to4;
            if(Category2to4Array.length>0){
                for(let i = 0;i<Category2to4Array.length;i++){
                    formData.append('Category2to4[]',Category2to4Array[i]);
                }
            }
            else{
                formData.append('Category2to4[]',[]);
            }
        }
        
        axios.patch(`${url}${id}`,formData);
  
    } catch (error) {
      console.log(error); 
    }
}

export const signOut = (data)=>{
    axios.patch(`${url}signOut`,data)
}

/////////////////////////////// DELETE REQUEST /////////////////////////////////////////
export const deleteUser = (id)=> {
    try {
      axios.delete(`${url}${id}`);
    } catch (error) {
        console.log(error);
    }
  
}