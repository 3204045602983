import axios from 'axios';

const url = 'https://scheduleease.co.za/api/notification/';

////////////////////////////// POST REQUEST ///////////////////////////////////

export const getPublicKey = async(info) => {
    try{
        // console.log('Getting Key')
        const {data} = await axios.post(`${url}publicKey`,info);
        return data.publicKey;
    } 
    catch(error){
        console.log(error)
    }
}

export const GenerateVapidKeys = ()=>{
    try{
        axios.post(`${url}generateKeys`);
    }
    catch(error){
        console.log(error)
    }
}

export const Subscribe = (newData)=>{ 
    
    try {
        
        axios.post(`${url}subscribe`,newData);
        
    }
    catch(error){
       console.log(error);
    }
}

export const Notify = (newData) => { 
    
    try {
        
        axios.post(`${url}notify`,newData);
        
    }
    catch(error){
       console.log(error);
    }
}