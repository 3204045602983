/*
 * © 2023-2024 Nqobani Vuka Dlamini
 * All rights reserved.
 */
import { Outlet, Link } from "react-router-dom";
import "../Style/Home.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "../Data/Session";
import { useContext } from "react";
import { GenerateVapidKeys } from "../Actions/Notification";

const serviceTags = ['Plumber','Electrician','House Cleaner','Pool Cleaner','Gardener','Painter','Waste Remover','Car Cleaner','Pest Controller','Pet Caretaker'] 
export default function Home(){
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const {userData} = useContext(UserDataContext);

    useEffect(()=>{
        const intervalId1 = setInterval(()=>{
            if(serviceTags && index == (serviceTags.length-1)){
                setOpacity(0);
                setTimeout(() => {
                    setIndex(0);
                    setOpacity(1);    
                }, 2000);
            }
            else{ 
                setOpacity(0);
                setTimeout(() => {
                    setIndex(index+1);
                    setOpacity(1);    
                }, 2000);
            }
        }, 6000); // Change culture tag every 5 seconds

        return () => {
            clearInterval(intervalId1); 
        };
    },[index])
    const output = (<div id="Home">
                        <div className="block1">
                            <img src='/Images/theme.jpg'></img>
                            <h1 className="hText1">Need A</h1>
                            <h1 className="hText2" style={{opacity:opacity}}>{serviceTags[index]}</h1>
                            <h1 className="hText3">We Got It</h1>
                            <button onClick={()=>(userData)?navigate('/services'):navigate('/signIn')}>Explore Our Services</button>
                        </div>
                        <div className="block2">
                            <div className="image-block">
                                <div className="home-frame"><img src='/Images/servicers.png'></img></div>
                            </div>
                            <div className="text-block">
                                <h1>Showcase Your Talent & Reach More Clients</h1>
                                <p>ScheduleEase is designed to help skilled labourers like plumbers, domestic workers, electricians etc. to market 
                                    themselves effectively. Our platform offers the tools and resources you need to highlight your expertise, 
                                    showcase your quality of work, and connect with more clients. By creating a professional profile, sharing 
                                    customer reviews, and leveraging our network, you can expand your reach, attract more business, and grow 
                                    your client base. Join our community today and let your talent shine!
                                </p>
                                <div className="button-holder">
                                    <button onClick={()=>navigate('/signUp1')}>Market your service</button>
                                    <div className="links">
                                        <p className="signIn-text">Have an account?</p>
                                        <p className="space"></p>
                                        <Link className="signIn-link" to='/signIn'>Sign In</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block3">
                            <div className="text-block2">
                                <div className="image-block2 l2">
                                    <div className="home-frame2"><img src='/Images/clients2.png'></img></div>
                                </div>
                                <h1>Find The Service You Need</h1>
                                <p>ScheduleEase is your one-stop destination for connecting with skilled professionals for all your home service needs.
                                   Whether you require house cleaning, plumbing, painting, pool cleaning, or any other labour service, our platform 
                                   makes it easy to find and hire trusted experts in your area. Browse through verified profiles, read customer reviews, 
                                   and choose the right professional to get the job done efficiently and effectively. Simplify your search and enjoy 
                                   peace of mind with our reliable and convenient service-finding solution.
                                </p>
                                <div className="button-holder">
                                    <button onClick={()=>(userData)?navigate('/services'):navigate('/signIn')}>Explore Ours Services</button>
                                </div>
                            </div>
                            <div className="image-block2 l1">
                                <div className="home-frame2"><img src='/Images/clients2.png'></img></div>
                            </div>
                        </div>

                    </div>);

    return output;
}