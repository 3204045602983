import '../Style/Checkout.css';
import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { UserDataContext } from '../Data/Session';
import CancelBlock from '../PopUps/CancelBlock';
import CardHandler from '../PopUps/CardHandler';
import RemoveCard from '../PopUps/RemoveCard';
import LocationSelector from '../Components/LocationSelector';
import Location from '../Components/Location';
import { pay } from '../Actions/Card';
import {updateUser} from '../Actions/User';
import { useNavigate } from 'react-router-dom'

export default function Checkout(){
    const {bookings, servicers, userData, ratings, cards, sessionId} = useContext(UserDataContext);
    const [myBooking, setBooking] = useState(null);
    const [servicer, setServicer] = useState(null)
    const [typesOpen, setOpen] = useState(false);
    const [top, setTop] = useState([70,110]);
    const [height, setHeight] = useState('0px');
    const [height2, setHeight2] = useState('0px')
    const [typeTxt, setText] = useState('House');
    const [paymentOption, setOption] = useState('Card');
    const [cardsOpen, setCardsOpen] = useState(false);
    const [deleteCard, setDeleteCard] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(0);
    const [selectedCard, setCard] = useState(0);
    const [rating, setRating] = useState(0);
    const [jobs, setJobs] = useState(null);
    const [CancelOpen, setCancelOpen] = useState(false);
    const [AddCard, setAddCard] = useState(false);
    const [myCards, setCards] = useState([]);
    const [required1, setReq1] = useState('none');
    const [required2, setReq2] = useState('none');
    const [required3, setReq3] = useState('none');
    const [required4, setReq4] = useState('none');
    const [required5, setReq5] = useState('none');
    const [required6, setReq6] = useState('none');
    const [required7, setReq7] = useState('none');
    const [timeRemaining, setTimeRemaining] = useState('0 days')
    const [Message0, setMessage0] = useState(false);
    const [Message1, setMessage1] = useState(false);
    const [Message2, setMessage2] = useState(false);
    const [Message3, setMessage3] = useState(false);
    const [Message4, setMessage4] = useState(false);
    const [Message5, setMessage5] = useState(false);
    const [Message6, setMessage6] = useState(false);
    const [Message7, setMessage7] = useState(false);
    const [Message8, setMessage8] = useState(false);
    const [Message9, setMessage9] = useState(false);
    const [Message10, setMessage10] = useState(false);
    const [Message11, setMessage11] = useState(false);
    const [Message12, setMessage12] = useState(false);
    const [Address, setAddress] = useState({buildingType:'House',line1: null, line2: null, line3: null, line4: null, line5:null,note:''});
    const houseSlots = ['','8:00-12:00','13:00-17:00'];
    const carSlots = ['','7:00-8:00','9:00-10:00','11:00-12:00','13:00-14:00','15:00-16:00'];
    const gardenSlots = ['','7:00-11:00','12:00-16:00'];
    const poolSlots = ['','8:00-10:00','11:00-13:00','14:00-16:00'];
    const negotiableSlots = ['','7:00-11:00','12:00-16:00'];

    const navigate = useNavigate();

    const cardBrandToIcon = {
        visa: '/Images/visa-blue.png',
        mastercard: '/Images/mastercard.png',
        null : '/Images/bank-card.png', // fallback for unknown brand
    };

    function is30DaysAfter(date1, date2) {
        const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        return (date2 - date1) >= THIRTY_DAYS_IN_MS;
    }
    function DifferenceInDays(date1, date2){
        const difference = date2 - date1;
        return Math.ceil(difference/(1000*60*60*24)); //difference in days
    } 
    function DifferenceInHours(date1, date2){
        const difference = date2 - date1;
        return Math.ceil(difference/(1000*60*60)); //difference in days
    } 
    
    function RemainingDays(date1, date2) {
        const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        const remainder = THIRTY_DAYS_IN_MS-(date2 - date1); // remainder in milliseconds
        return Math.ceil(remainder/(1000*60*60*24)); // remainder in days
    }
    function RemainingHours(date1, date2) {
        const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        const remainder = THIRTY_DAYS_IN_MS-(date2 - date1); // remainder in milliseconds
        return Math.ceil(remainder/(1000*60*60)); // remainder in hours
    }
    const Pay = async()=>{
        let allGood = true;
       
        if(!Address.line1){
            allGood = false;
            setReq1('block')
        }
        if(!Address.line2){
            allGood = false;
            setReq2('block');
        }
        if(Address.buildingType!='House' && !Address.line3){
            allGood = false;
            setReq3('block');
            setReq5('block');
            setReq7('block');
        }
        if(Address.buildingType!='House' && Address.buildingType!='Complex / Estate' && !Address.line4){
            allGood = false;
            setReq4('block');
            setReq6('block');
        }
        if(userData.CardOption==false){
            allGood = false;
            setMessage1(true);
        }
        else{
            if(userData.Category2to4 && userData.Category2to4.length>0){
                const index = userData.Category2to4.length-1;
                const codeObject = userData.Category2to4[index];
                // console.log('Within Cat Checker: ',codeObject);
                switch(codeObject.code){
                    //retry after 1 hr
                    case '24':
                        if(DifferenceInHours(new Date(codeObject.date), new Date()) <= 1){
                            setMessage2(true);
                            allGood = false;
                        }
                        break;
                    //retry after 24 hrs
                    case '25':
                    case '61':
                    case '65':
                    case '75': 
                        if(DifferenceInHours(new Date(codeObject.date), new Date()) <= 24){
                            setMessage2(true);
                            allGood = false;
                        }
                        break;
                    //retry after 2 days
                    case '26':
                        if(DifferenceInDays(new Date(codeObject.date), new Date()) <= 2){
                            setMessage2(true);
                            allGood = false;
                        }
                        break;
                    //retry after 4 days
                    case '27':
                        if(DifferenceInDays(new Date(codeObject.date), new Date()) <= 4){
                            setMessage2(true);
                            allGood = false;
                        }
                        break;
                    //retry after 6 days
                    case '28':
                        if(DifferenceInDays(new Date(codeObject.date), new Date()) <= 6){
                            setMessage2(true);
                            allGood = false;
                        }
                        break;
                    //retry after 8 days
                    case '29':
                        if(DifferenceInDays(new Date(codeObject.date), new Date()) <= 8){
                            setMessage2(true);
                            allGood = false;
                        }
                        break;
                    //retry after 10 days
                    case '30':
                        if(DifferenceInDays(new Date(codeObject.date), new Date()) <= 10){
                            setMessage2(true);
                            allGood = false;
                        }
                        break;
                }
            }
        }
        if(allGood){
            if(paymentOption=='Card' && myCards.length>0){
                //console.log('Paying!!!');
                const data = {
                                address: {...Address,city:(userData.city)?userData.city:null},
                                bookingId: myBooking._id,
                                paymentOption: paymentOption,
                                card: myCards[selectedCard],
                                sessionId: sessionId,
                                amount: myBooking.amount+30,
                                userId: userData._id,
                                Category1: (userData.Category1)?userData.Category1:[],
                                Category2to4: (userData.Category2to4)?userData.Category2to4:[]
                            };

                const response = await pay(data);
                // console.log("Code: ",response.resultCode);

                switch(response.resultCode){
                    //successful
                    case '00':
                        setMessage0(true);
                        navigate(`/booking/${response.bookingId}`)
                        break;
                    //reattempts prohited, contact card issuer
                    case '04':
                    case '07':
                    case '12':
                    case '14':
                    case '15':
                    case '41':
                    case '43':
                    case '46':
                    case '57':
                    case '59':
                    case '62':
                    case '78':
                    case '93':    
                    case 'R0':
                    case 'R1':
                    case 'R3':
                        setMessage3(true);
                        break;
                    //retry after 1 hr
                    case '24':
                        setMessage4(true);
                        break;
                    //retry after 24 hrs
                    case '25':
                    case '61':
                    case '65':
                    case '75':    
                        setMessage5(true);
                        break;
                    //retry after 2 days
                    case '26':
                        setMessage6(true);
                        break;
                    //retry after 4 days
                    case '27':
                        setMessage7(true);
                        break;
                    //retry after 6 days
                    case '28':
                        setMessage8(true);
                        break;
                    //retry after 8 days
                    case '29':
                        setMessage9(true);
                        break;
                    //retry after 10 days
                    case '30':
                        setMessage10(true);
                        break;
                    //insufficient funds
                    case '51':
                        setMessage11(true);
                        break;    
                    //Unauthorised card
                    case '99':
                        setMessage12(true);
                        break;  
                    //retry later              
                    default:
                        setMessage2(true);
                        break;
                }
            }
            else if(paymentOption=='Cash'){
                const data = {
                                address: {...Address,city:(userData.city)?userData.city:null},
                                bookingId: myBooking._id,
                                paymentOption: paymentOption,
                                sessionId: sessionId,
                                userId: userData._id
                            };

                const response = await pay(data);

                switch(response.resultCode){
                    //successful
                    case '00':
                        setMessage0(true);
                        navigate(`/booking/${response.bookingId}`)
                        break;
                    //retry later              
                    default:
                        setMessage2(true);
                        break;
                }
            }
        }
    }

    function BType(type){
        switch(type){
            case 0:
                setText('House');
                setTop([70,110]);
                setAddress({...Address,buildingType:'House'})
                break
            case 1:
                setText('Apartment');
                setTop([110,150]);
                setAddress({...Address,buildingType:'Apartment'})
                break
            case 2:
                setText('Complex / Estate');
                setTop([110,150]);
                setAddress({...Address,buildingType:'Complex / Estate'})
                break
            case 3:
                setText('Office');
                setTop([110,150]);
                setAddress({...Address,buildingType:'Office'})
                break
        }
    }

    function CarCost(data){
        let cost = 0;
        if(data.interior){
            cost += 20;
        }
        if(data.polish){
            cost += 30;
        }
        if(data.carType=='Light Vehicle'){
            cost += 80;
        }
        else if(data.carType=='2 Axle Heavy Vehicle'){
            cost += 120;
        }
        else if(data.carType=='3 & 4 Axle Heavy Vehicle'){
            cost += 250;
        }
        
        return cost;
    }

    function Period(slotNo){
        if(myBooking.service == 'House Cleaning'){
            return houseSlots[slotNo];
        }
        else if(myBooking.service == 'Pool Cleaning'){
            return poolSlots[slotNo];
        }
        else if(myBooking.service == 'Car Cleaning'){
            return carSlots[slotNo];
        }
        else if(myBooking.service == 'Gardening'){
            return gardenSlots[slotNo];
        }
    }

    const AddressChange = (e)=>{
        e.preventDefault();
        const {value, name} = e.target;

        setAddress({
            ...Address,
            [name]:value
        })

        if(name=='line1'){
            setReq1('none');
        }
        else if(name=='line2'){
            setReq2('none');
        }
        else if(name=='line3'){
            setReq3('none');
            setReq5('none');
            setReq7('none');
        }
        else if(name=='line4'){
            setReq4('none');
            setReq6('none');
        }
    }

    useEffect(()=>{
        if(bookings && userData && servicers){
            const data = bookings.find((Data)=>Data.bookerId==userData._id && Data.paidDate==null && Data.completedDate==null);
            setBooking(data);

            if(data){
                const data2 = servicers.find((Data)=>Data._id==data.servicerId);
                setServicer(data2);
            }
        }
    },[bookings, userData, servicers])

    useEffect(()=>{
        if(ratings && servicer){
            const ratingArr = ratings.filter((rating)=>rating.servicerId==servicer._id);
            let ratingAve = 0;
            for(let i = 0; i<ratingArr.length; i++){
                ratingAve += ratingArr[i].rating;
            }
            ratingAve = (ratingArr.length>0)?Math.round(ratingAve/ratingArr.length):0;  
            setRating(ratingAve);  
        }
    },[ratings, servicer])

    useEffect(()=>{
        if(cards && userData){
            setCards(cards.filter((card)=>(card.userId==userData._id)))
        }
        // Check the card blockage date
        if(userData && userData.CardOption==false && userData.BlockageDate){
            if(is30DaysAfter(new Date(userData.BlockageDate))){
            // if(1==1){
                updateUser(userData._id,{CardOption:true, sessionId:sessionId});
            }
            else{
                const difference1 = RemainingDays(new Date(userData.BlockageDate), new Date().getTime());
                const difference2 = RemainingHours(new Date(userData.BlockageDate), new Date().getTime());

                if(difference1>1){
                    setTimeRemaining(`${difference1} days`);
                }
                else{
                    setTimeRemaining(`${difference2} hours`);
                }
            }
        }
    },[cards, userData])

    useEffect(()=>{
        if(bookings && servicer){
            const data = bookings.filter((booking)=>booking.servicerId == servicer._id && booking.progress.stage4a==true && booking.progress.stage4b==true);
            setJobs(data.length);
        }
    },[bookings, servicer])

    if(myBooking && servicer){
        const output = (<div id='Checkout'>
            {CancelOpen && (
                <CancelBlock bookingId={myBooking._id} setCancelOpen={setCancelOpen} ></CancelBlock>
            )}
            {AddCard==true && ( 
                <CardHandler setAddCard={setAddCard} useCase={'c'}></CardHandler>
            )}
            {deleteCard==true &&(
                <RemoveCard cardId={myCards[toBeDeleted]._id} setDeleteCard={setDeleteCard} setCard={setCard} useCase="Checkout"></RemoveCard>
            )}
            
            {Message0==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage0(false)}>&#10006;</p>
                    <p className='popup-message'>Payment Successful</p>
                </div>
            )}
            {Message1==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage1(false)}>&#10006;</p>
                    <p className='popup-message'>Payment re-attempt limit reached, card payment option is closed for {timeRemaining}</p>
                </div>
            )}
            {Message2==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage2(false)}>&#10006;</p>
                    <p className='popup-message'>Retry later</p>
                </div>
            )}
            {Message3==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage3(false)}>&#10006;</p>
                    <p className='popup-message'>We're sorry, but this transaction cannot be processed. Please try a different payment method or contact your card issuer for further assistance.</p>
                </div>
            )}
            {Message4==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage4(false)}>&#10006;</p>
                    <p className='popup-message'>Retry after 1 hour</p>
                </div>
            )}
            {Message5==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage5(false)}>&#10006;</p>
                    <p className='popup-message'>Retry after 24 hours</p>
                </div>
            )}
            {Message6==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage6(false)}>&#10006;</p>
                    <p className='popup-message'>Retry after 2 days</p>
                </div>
            )}
            {Message7==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage7(false)}>&#10006;</p>
                    <p className='popup-message'>Retry after 4 days</p>
                </div>
            )}
            {Message8==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage8(false)}>&#10006;</p>
                    <p className='popup-message'>Retry after 6 days</p>
                </div>
            )}
            {Message9==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage9(false)}>&#10006;</p>
                    <p className='popup-message'>Retry after 8 days</p>
                </div>
            )}
            {Message10==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage10(false)}>&#10006;</p>
                    <p className='popup-message'>Retry after 10 days</p>
                </div>
            )}
            {Message11==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage11(false)}>&#10006;</p>
                    <p className='popup-message'>Insufficient funds</p>
                </div>
            )}
            {Message12==true && (
                <div className='checkout-popup'>
                    <p className='popup-closer' onClick={()=>setMessage12(false)}>&#10006;</p>
                    <p className='popup-message'>Your card was unauthorized, re-add the card and run through the authentication process</p>
                </div>
            )}

            <div className='settings'>
                <h1 className='page-title'>Checkout</h1>
                <div className='address-block'>
                    <div className='block-title'>
                        <h3>Address</h3>
                    </div>
                    <div className='address-body'>
                        <div className="map-block">
                            <Location streetNo={Address.line1} streetName={Address.line2}></Location>
                        </div>
                        <div className='address-settings'>
                            <div className='building-type'>
                                <p className='selected-btype'>Building Type : {typeTxt}</p> 
                                <img src={(typesOpen)?'/Images/up-arrow.png':'/Images/down-arrow.png'} onClick={()=>{setOpen(!typesOpen); (typesOpen)?setHeight('0px'):setHeight('200px')}}></img>
                            
                                <div className='types' style={{height:height}}>
                                    <p className='type-card' onClick={()=>BType(0)}>House</p>
                                    <p className='type-card' onClick={()=>BType(1)}>Apartment</p>
                                    <p className='type-card' onClick={()=>BType(2)}>Complex / Estate</p>
                                    <p className='type-card' onClick={()=>BType(3)}>Office</p>
                                </div>
                            
                            </div>
                            {typeTxt=='Apartment' &&(<>  
                                <div className='Num-carrier' style={{top:'70px'}}>
                                    <p className="required-tag" style={{display:required3}}>required</p>
                                    <input name='line3' type='text' className="Num-input" placeholder='Unit No' value={Address.line3} onChange={AddressChange}></input> 
                                </div>
                                <div className='Name-carrier' style={{top:'70px'}}>
                                    <p className="required-tag" style={{display:required4}}>required</p>
                                    <input name='line4' type='text' className="Name-input" placeholder='Apartment Name'  value={Address.line4} onChange={AddressChange}></input>
                                </div>
                            </>)}
                            {typeTxt=='Office' &&(<>   
                                <div className='Num-carrier' style={{top:'70px'}}>
                                    <p className="required-tag" style={{display:required5}}>required</p>
                                    <input name='line3' type='text' className="Num-input" placeholder='Floor No' value={Address.line3} onChange={AddressChange}></input> 
                                </div>
                                <div className='Name-carrier' style={{top:'70px'}}>
                                    <p className="required-tag" style={{display:required6}}>required</p>
                                    <input name='line4' type='text' className="Name-input" placeholder='Company Name, Building Name' value={Address.line4} onChange={AddressChange}></input>
                                </div>
                            </>)}
                            {typeTxt=='Complex / Estate' &&(<>
                                <div className='Estate-carrier' style={{top:'70px'}}>
                                    <p className="required-tag" style={{display:required7}}>required</p>
                                    <input name='line3' type='text' className="Estate-input" placeholder='Complex / Estate Name' value={Address.line3} onChange={AddressChange}></input>
                                </div>
                            </>)}
                            <div className='Num-carrier' style={{top:top[0]}}>
                                <p className="required-tag" style={{display:required1}}>required</p>
                                <input name='line1' type='text' className="Num-input" placeholder='Str No' value={Address.line1} onChange={AddressChange}></input> 
                            </div>
                            <div className='Name-carrier' style={{top:top[0]}}>
                                <p className="required-tag" style={{display:required2}}>required</p>
                                <input name='line2' type='text' className="Name-input" placeholder='Street Name' value={Address.line2} onChange={AddressChange}></input>
                            </div>
                            <input name='note' type='text' className='Note-input' placeholder='Note' style={{top:top[1]}} onChange={AddressChange}></input>
                        </div>
                    </div>
                </div>
                <div className='payment-block'>
                    <div className='block-title'>
                        <h3>Payment Method</h3>
                    </div>
                    <div>
                        <div className='payment-options'>
                            <div className={`card ${(paymentOption=='Card')?'selected-option':''}`} onClick={()=>setOption('Card')}><p>Card</p></div>
                            <div className={`cash ${(paymentOption=='Cash')?'selected-option':''}`} onClick={()=>setOption('Cash')}><p>Cash</p></div>
                        </div>
                        <div className='card-options'>
                            {paymentOption=='Card' &&(<>  
                                <div className='logo-frame'>
                                    {myCards.length>0 && (
                                        <img src={cardBrandToIcon[myCards[selectedCard].brand]}></img>
                                    )}
                                </div>
                                <div  className='card-details'>
                                    <p>{(myCards.length==0)?'':`${(myCards[selectedCard].brand=='visa')?'Visa':'Mastercard'}....${myCards[selectedCard].last4}`}</p>
                                    <p className='card-expiry'>{(myCards.length==0)?'':`${myCards[selectedCard].expiry.slice(0,2)}/${myCards[selectedCard].expiry.slice(2)}`}</p>
                                </div> 
                                <img className='arrow' src={(cardsOpen)?'/Images/up-arrow.png':'/Images/down-arrow.png'} onClick={()=>{setCardsOpen(!cardsOpen); setHeight2((cardsOpen)?'0px':'210px')}}></img>
                            </>)}    
                            {paymentOption=='Cash' &&(<>  
                                <p className='payment-disclaimer'>Please have the exact amount ready for the service provider, to avoid issues with change</p>
                            </>)}    
                        </div>
                    </div>
                    {paymentOption=='Card' && (
                        <div className='card-list' style={{height:height2}}>
                            <p className='addCard-link' onClick={()=>setAddCard(true)}>Add a new card</p>
                            {myCards && myCards.length>0 && myCards.map((data, index)=>(
                            <div className='card-option'>
                                <div className='card-data' onClick={()=>setCard(index)}>
                                    <div className='logo-frame'>
                                        <img src={cardBrandToIcon[data.brand]}></img>
                                    </div>
                                    <div  className='card-details'>
                                        <p>{(data.brand=='visa')?'Visa':'Mastercard'}....{data.last4}</p>
                                        <p className='card-expiry'>{data.expiry.slice(0,2)}/{data.expiry.slice(2)}</p>
                                    </div>      
                                </div>
                                <img className='card-delete' src='/Images/delete.png' onClick={()=>{setDeleteCard(true);setToBeDeleted(index);}}></img>  
                            </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className='checkout-data'>
                <div className='block1'>
                    <p className='booking-statement'>Booking of {myBooking.service} from:</p>
                    <div className='data-holder'>
                        <div className='servicer-image'>
                            <div className='profile-frame'>
                                <img src={(servicer.profileLink)?servicer.profileLink:'/Images/user.png'}></img>
                            </div>
                        </div>
                        <div className='servicer-data'>
                            <p className='booking-name'>{servicer.name}</p>
                            <div className='rating'>
                                <p>{rating.toFixed(1)}</p>
                                <img src='/Images/star-yellow.png'></img>
                            </div>
                            <p className='booking-surname'>{servicer.surname}</p>
                            <p className='booking-jobs'>{(jobs)?jobs:'0'} Jobs</p>
                        </div>
                    </div>
                </div>
                <div className='block2'>
                    { myBooking.type=='negotiable' &&(
                        <div className='slot-card'>
                            <p className='sc-name'>Slot {myBooking.appointment.slot1}</p>
                            <p className='sc-fee'>FREE</p>
                            <p className='sc-period'>{negotiableSlots[myBooking.appointment.slot1]}</p>
                            <p className='sc-date'>{myBooking.appointment.date}</p>
                        </div>
                    )}
                    { myBooking.type=='fixed' && (
                        <div className='slot-card'>
                            <p className='sc-name'>Slot {myBooking.appointment.slot1}</p>
                            <p className='sc-fee'>FREE</p>
                            <p className='sc-period'>{Period(myBooking.appointment.slot1)}</p>
                            <p className='sc-date'>{myBooking.appointment.date}</p>
                        </div>
                    )}
                    { myBooking.type=='fixed' && myBooking.appointment.slot2 &&(
                        <div className='slot-card'>
                            <p className='sc-name'>Slot {myBooking.appointment.slot2}</p>
                            <p className='sc-fee'>FREE</p>
                            <p className='sc-period'>{Period(myBooking.appointment.slot2)}</p>
                            <p className='sc-date'>{myBooking.appointment.date}</p>
                        </div>
                    )}
                    { myBooking.service=='House Cleaning' &&(
                        <div className='house-card'>
                            <p className='hc-name'>Common Areas</p>
                            <p className='hc-fee'>ZAR { myBooking.house.commonCost}</p>
                            <div className='extras-block'>    
                                <p className='extra' style={{display:( myBooking.house.laundry)?'block':'none'}}>laundry</p>
                                <p className='extra' style={{display:( myBooking.house.cabinets)?'block':'none'}}>cabinets</p>
                                <p className='extra' style={{display:( myBooking.house.windows)?'block':'none'}}>windows</p>
                                <p className='extra' style={{display:( myBooking.house.fridge)?'block':'none'}}>fridge</p>
                                <p className='extra' style={{display:( myBooking.house.oven)?'block':'none'}}>oven</p>
                            </div>
                        </div>
                    )}
                    { myBooking.service=='House Cleaning' &&  myBooking.house.rooms.map((data,index)=>
                        <div className='room-card'>
                            <p className='rc-name'>Room {index+1}</p>
                            <p className='rc-fee'>ZAR {(data==true)?'35':'15'}</p>
                            <p className='room-windows' style={{display:(data==true)?'block':'none'}}>windows</p>
                        </div>
                    )} 
                    { myBooking.service=='House Cleaning' &&  myBooking.house.bathrooms.map((data,index)=>
                        <div className='bathroom-card'>
                            <p className='bc-name'>Bathroom {index+1}</p>
                            <p className='bc-fee'>ZAR {(data==true)?'55':'35'}</p>
                            <p className='bathroom-windows' style={{display:(data==true)?'block':'none'}}>windows</p>
                        </div>
                    )}
                    { myBooking.service=='Pool Cleaning' &&(
                        <div className='pool-card'>
                            <p className='pc-name'>Pool Cleaning</p>
                            <p className='pc-fee'>ZAR {myBooking.amount}</p>
                            <div className='extras-block'>
                                <p className='extra' style={{display:( myBooking.pool.tile)?'block':'none'}}>tile</p>
                                <p className='extra' style={{display:( myBooking.pool.deck)?'block':'none'}}>deck</p>
                                <p className='extra' style={{display:( myBooking.pool.inspection)?'block':'none'}}>inspection</p>
                            </div>
                        </div>
                    )}
                    { myBooking.service=='Gardening' &&(
                        <div className='garden-card'>
                            <p className='gc-name'>Pool Cleaning</p>
                            <p className='gc-fee'>ZAR {myBooking.amount}</p>
                            <div className='extras-block'>
                                <p className='extra' style={{display:( myBooking.garden.fertilize)?'block':'none'}}>fertilize</p>
                                <p className='extra' style={{display:( myBooking.garden.control)?'block':'none'}}>pest control</p>
                                <p className='extra' style={{display:( myBooking.garden.inspection)?'block':'none'}}>inspection</p>
                            </div>
                        </div>
                    )}
                    {myBooking && myBooking.service=='Car Cleaning' && myBooking.car.map((data)=>
                        <div className='car-card'>
                            <p className='cc-name'>{data.carType}</p>
                            <p className='cc-fee'>ZAR {CarCost(data)}</p>
                            <div className='extras-block'>
                                <p className='extra' style={{display:(data.interior)?'block':'none'}}>interior</p>
                                <p className='extra' style={{display:(data.polish)?'block':'none'}}>polish tyres</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className='block3'>
                    <div className='b3-left'>
                        <p className='subtotal-statement'>Sub-Total:</p>
                        <p className='fee-statement'>Service Fee:</p>
                        <p className='total-statement'>Total:</p>
                    </div>
                    <div className='b3-right'>
                        <p className='subtotal-value'>ZAR {myBooking.amount}</p>
                        <p className='fee-value'>ZAR 30</p>
                        <p className='total-value'>ZAR {myBooking.amount+30}</p>
                    </div>
                </div>
                <div className='block4'>
                    <button className='pay-button' onClick={Pay}>Pay</button>
                    <button className='cancel-button' onClick={()=>setCancelOpen(true)} >Cancel</button>
                </div>
            </div>

            <LocationSelector></LocationSelector>
        </div>
        )

        return output;
    }
    else{
        const output = (<div id='Checkout'>    
            <div className='settings'>
                <h1 className='page-title'>No Booking To Checkout ....</h1>
                <div className='icon-block' >  
                    <img className='no-checkout' src='/Images/cart.png'></img>
                </div>
            </div>
            <div className='checkout-data'>
                <div className='block1'>
                  
                </div>
                <div className='block2'>

                </div>
                <div className='block3'>
                    <p className='subtotal-statement'>Sub-Total:</p>
                    <p className='fee-statement'>Service Fee:</p>
                    <p className='total-statement'>Total:</p>
                    <p className='subtotal-value'>ZAR -</p>
                    <p className='fee-value'>ZAR -</p>
                    <p className='total-value'>ZAR -</p>
                </div>
                <div className='block4'>
                    <button className='pay-button' style={{opacity:'0.5'}}>Pay</button>
                    <button className='cancel-button' style={{opacity:'0.5'}}>Cancel</button>
                </div>
            </div>
        </div>
        )

        return output;
    }
}