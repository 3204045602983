import '../Style/LocationSelector.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, useContext } from 'react';
import { UserDataContext } from "../Data/Session.js";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap, Popup } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import axios from 'axios';
import {updateUser} from '../Actions/User.js';
import {updateServicer} from '../Actions/Servicer.js';

export default function LocationSelector(){
    const navigate = useNavigate();
    const provider = new OpenStreetMapProvider();
    const [position, setPosition] = useState({ lat: -26.205, lng: 28.049722 }); // Default position
    const [searchResults, setSearchResults] = useState([]); // Store search results
    const [city1, setCity1] = useState('');
    const [city2, setCity2] = useState('');
    const [open, setOpen] = useState(false);
    const [starting, setStarting] = useState(true);
    const mapRef = useRef(); // Reference to the map instance
    const {userData, sessionId} = useContext(UserDataContext);

    // Function to fetch address based on lat and lng
    const fetchAddress = async (lat, lng) => {
      const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
      // console.log('New Location2')
      try {
        const response = await axios.get(url);
        const result = response.data;
        // console.log('New Location3: \n',result)
        if (result && result.address) {
          const {city} = result.address;
          // console.log('City Changed To: ',city)
          setCity2(city);
        }
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    };

    // Function to handle search result selection
    const handleSearchResult = (lat, lng) => {
      setPosition({ lat, lng }); // Update position state
      if (mapRef.current) {
        mapRef.current.setView([lat, lng], 16); // Set the view of the map to the new position
      }
      // console.log('New Location1')
      fetchAddress(lat, lng); // Fetch address
      // setSearchResults([]); // Clear the search results
    };

    // MapComponent handles marker and popup
    const MapComponent = () => {
      const map = useMapEvents({
        locationfound: (location) => {
            setPosition((prevPosition) => {
              const newLat = location.latlng.lat;
              const newLng = location.latlng.lng;
              if (prevPosition?.lat !== newLat || prevPosition?.lng !== newLng) {
                fetchAddress(newLat, newLng)
                return { lat: newLat, lng: newLng };
              }
              return prevPosition; // No state update if the same position
            });
            map.flyTo(location.latlng, map.getZoom());
        }
      });
  
      useEffect(() => {
        // if(starting==true && (userData && !userData.location) || !userData){
        if(starting==true && (userData && !userData.location)){
          map.locate({ setView: true, maxZoom: 16 });
          setStarting(false);
        }
      }, [userData]);

      useEffect(() => {
        if(position){
            map.flyTo(position, map.getZoom()); // Set the view of the map whenever position changes
            fetchAddress(position.lat, position.lng)
        }
      }, [position]);
  
      return (position)?(<Marker position={position}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>) 
                        : 
                        (<Marker position={{lat: -26.205, lng: 28.049722 }}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>);
    };

    const handleSearch = async (query) => {
        try {
          const results = await provider.search({ query });
          if (results && results.length > 0) {
            setSearchResults(results); // Update the state with search results
          }
        } catch (error) {
          console.error('Error during search:', error);
        }
    };

    useEffect(()=>{
        // const SetAddress = async(lat, lng)=>{
        //   const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
    
        //   try {
        //     // console.log('Flag1')
        //     const response = await axios.get(url);
        //     // console.log('Flag2')
        //     const result = response.data;
      
        //     if (result && result.address) {
        //       const {city} = result.address;
        //       setCity1(city);
        //     }
        //   } catch (error) {
        //     console.error('Error fetching address:', error);
        //   }
        // }

      if(userData && userData.location){
        // SetAddress(userData.location.lat, userData.location.lng)
        setPosition({lat:userData.location.lat, lng:userData.location.lng})
      }
      if(userData && userData.city){
        setCity1(userData.city)
      }
    },[userData])

    function SubmitLocation(){
      if(position && userData && userData.type=='User' && sessionId && city2){
        updateUser(userData._id,{location:position, sessionId:sessionId, city:city2})
        setOpen(false);
      }
      else if(position && userData && userData.type=='Servicer' && sessionId && city2){
        updateServicer(userData._id,{location:position, sessionId:sessionId, city:city2})
        setOpen(false);
      }
    }

    const output = (
      <div id="LocationSelector">
          <div className='LocationButton' onClick={()=>setOpen(true)}>
              <img className='pin' src='/Images/pin.png' alt="Pin" />
              <p className='city'>{(city1 && city1!='')?city1:'....?'}</p>
          </div>
          <div className='background' style={{height: (open==true)?'100vh':'0vh'}}>
              <div className='location-block'>
                  <input className='search-location' type='text' onChange={(e) =>handleSearch(e.target.value)} />
                  <p className='close-button' onClick={()=>setOpen(false)}>&#10006;</p>
                  <div className='map'>
                      <MapContainer
                          center={position}
                          style={{ height: '220px', width: '220px' }}
                          zoom={13}
                          scrollWheelZoom={false}
                          whenCreated={(mapInstance) => (mapRef.current = mapInstance)} // Get the map instance
                      >
                          <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <MapComponent />
                      </MapContainer>
                  </div>
                  <div className='results'>
                      <h2>Results</h2>
                      <div className='list'>
                          {searchResults && searchResults.map((result) =>
                              <div className='result-card' onClick={() => handleSearchResult(result.y, result.x)} key={result.x}>
                                  <p>{result.label}</p>
                              </div>
                          )}
                      </div>
                  </div>
                  <div className='submit-button' style={{display:(city2 && city2 != '')?'grid':'none',cursor:'pointer'}} onClick={SubmitLocation}>
                    <p className='submit-statement'>Submit Location</p>
                    <img className='submit-pin' src='/Images/pin.png'></img>
                    <p className='submit-city'>{city2}</p>
                  </div>
              </div>
          </div>
      </div>
    );

    return output;
}
