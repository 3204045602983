import '../Style/CardHandler.css';
import valid from 'card-validator';
import { addCard } from '../Actions/Card';
import { useContext, useState, useEffect } from 'react';
import { UserDataContext } from '../Data/Session';
import { authorizeCard } from '../Actions/Card';
import axios from 'axios';

export default function CardHandler({setAddCard, useCase}){
    const [card, setCard] = useState({number:null, cvv:null, expiry:null, brand:null, holder:null, type:'CD'})
    const {userData, sessionId} = useContext(UserDataContext);
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [cardHolder, setCardHolder] = useState('');

    const [numberTag, setTag1] = useState(false);
    const [expiryTag, setTag2] = useState(false);
    const [cvvTag, setTag3] = useState(false);
    const [holderTag, setTag4] = useState(false);

    const [preconUrl, setPreUrl] = useState(null);
    const [redirectUrl, setRedirUrl] = useState(null);
    const [preconInputs, setPreconInputs] = useState(null);
    const [redirectInputs, setRedirInputs] = useState(null);

    const [lock, setLock] = useState(false); //Used to avoid multiple card additions from multiple clicks of the submit button

    const [message, setMessage] = useState(false); // pop up message handler for "Retry adding card"

    //Getting the client's device's ip address
    const getIpAddress = async () => {
        try{
            const response = await fetch('https://api64.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        }
        catch(error){
            console.error(error);
        }
    };
    // Card Number Formatting
    const handleCardNumberChange = (e) => {
        e.preventDefault();
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        let formattedValue = '';

        for (let i = 0; i < value.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedValue += ' '; // Add a space after every 4 digits
            }
            formattedValue += value[i];
        }

        setCardNumber(formattedValue);
        const cardBrand = valid.number(value).card?valid.number(value).card.type:null;
        setCard({...card, number: value, brand: cardBrand});
        setTag1(false);
    };

    // Expiry Date Formatting
    const handleExpiryDateChange = (e) => {
        e.preventDefault();
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        let formattedValue = '';

        if (value.length >= 2) {
            formattedValue = value.slice(0, 2) + '/' + value.slice(2, 4);
            setCard({...card,expiry: value.slice(0, 2)+value.slice(2, 4) });
        } else {
            formattedValue = value;
        }

        setExpiryDate(formattedValue);
        setTag2(false);
    };

    // CVV Validation
    const handleCvvChange = (e) => {
        e.preventDefault();
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setCvv(value.slice(0, 3)); // Limit to 3 digits
        setCard({...card, cvv: value.slice(0, 3)})
        setTag3(false);
    };

    const handleHolderChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setCardHolder(value);
        setCard({...card, holder: value})
        setTag4(false);
    }

    const handleTypeChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setCard({...card,type:value});
    }

    const cardBrandToIcon = {
        visa: '/Images/visa-blue.png',
        mastercard: '/Images/mastercard.png',
        null : '/Images/bank-card.png', // fallback for unknown brand
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cardValidation = valid.number(card.number).isValid;
        const expiryValidation = valid.expirationDate(card.expiry).isValid;
        const cvvValidation = valid.cvv(card.cvv).isValid;
        const holderValidation = (card.holder && card.holder.trim()!='');

        if(!cardValidation){
            setTag1(true);
        }
        if(!expiryValidation){
            setTag2(true);
        }
        if(!cvvValidation){
            setTag3(true);
        }
        if(!holderValidation){
            setTag4(true);
        }
        if(cardValidation && expiryValidation && cvvValidation && holderValidation && userData){
            
            const ip = await getIpAddress();
            // console.log('IP Address: ',ip);
            const data1 = {
                customerIp: ip,
                language: navigator.language || navigator.userLanguage,
                screenHeight: window.screen.height,
                screenWidth: window.screen.width,
                timezone: -new Date().getTimezoneOffset(),
                userAgent: navigator.userAgent,
                number:card.number, 
                expiry:card.expiry, 
                cvv:card.cvv, 
                holder:card.holder, 
                brand:card.brand, 
                userId:userData._id,
                phone: userData.number,
                useCase: useCase
            };
            const data2 = {
                    sessionId:sessionId, 
                    number:card.number, 
                    expiry:card.expiry, 
                    cvv:card.cvv, 
                    holder:card.holder, 
                    brand:card.brand, 
                    type:card.type, 
                    userId:userData._id
            }

            setLock(true);
            addCard(data2);
            const response = await authorizeCard(data1);
            
            if(response.retry==true){
                setMessage(true);
                setLock(false)
            }
            else if(response.data){
                setRedirUrl((response.data.url)?response.data.url:null);
                setRedirInputs((response.data.parameters)?response.data.parameters:null);
                setPreUrl((response.data.preconditions && response.data.preconditions[0] && response.data.preconditions[0].url)?response.data.preconditions[0].url:null);
                setPreconInputs((response.data.preconditions && response.data.preconditions[0] && response.data.preconditions[0].parameters)?response.data.preconditions[0].parameters:[]);
            }

        }
    };

    useEffect(()=>{
        if(redirectUrl && redirectInputs && preconUrl && preconInputs){
            // console.log("Data1: ",redirectUrl);
            // console.log("Data2: ",redirectInputs);
            // console.log("Data3: ",preconUrl);
            // console.log("Data4: ",preconInputs);

            function submitAuthForm() {
                document.forms['authForm'].submit();
            }
            function submitHiddenForm() {
                document.forms['hiddenForm'].submit();
                document.forms['hiddenForm'].onsubmit = submitAuthForm();
            }
            window.onload = submitHiddenForm();
            setAddCard(false);
        }
        else if(redirectUrl && redirectInputs){
            // console.log("Data1: ",redirectUrl);
            // console.log("Data2: ",redirectInputs);

            function submitAuthForm() {
                document.forms['authForm'].submit();
            }
            window.onload = submitAuthForm();
            setAddCard(false);
        }
    },[redirectUrl, redirectInputs, preconUrl, preconInputs])

    const output = (
        <div id='CardHandler'>
            <>
                <form className='hiddenForm' name='hiddenForm' action={preconUrl} method='POST' target='hiddenFrame'>
                    {preconInputs && preconInputs.map((data, index)=>
                        <input type='hidden' name={data.name} value={data.value} key={'preconInput'+index}></input>
                    )}
                </form>
                <iframe name="hiddenFrame" style={{display: "none"}}></iframe>
                <form className='authForm' name='authForm' action={redirectUrl} method='POST'>
                    {redirectInputs && redirectInputs.map((data, index)=>
                        <input type='hidden' name={data.name} value={data.value} key={'redirectInput'+index}></input>
                    )}
                </form>
            </>

            {message==true && (
                <div className='popup-message'>
                    <p className='close-popup' onClick={()=>setMessage(false)}>&#10006;</p>
                    <p className='notification'>Check card information and retry adding card</p>
                </div>
            )}

            <div className='AddCard'>
                <p className='close-addcard' onClick={()=>setAddCard(false)}>&#10006;</p>
                <>
                    <h2 className='addCard-heading'>Enter Card Details</h2>
                    <div className='card-types'>
                        <label className='label'>Card Type:</label>
                        <div>
                            <label className='type1'>
                                <input type='radio' name='card_type' value='CD' onChange={handleTypeChange} defaultChecked></input>
                                Credit
                            </label>
                            <label className='type2'>
                                <input type='radio' name='card_type' value='DB' onChange={handleTypeChange}></input>
                                Debit
                            </label>
                        </div>
                    </div>
                    <div className='holder'>
                        <label className='label'>Card Number:</label>
                        <div className='cardNumber'>
                            <p className='invalid-tag' style={{display:(numberTag==true)?'block':'none'}}>invalid</p>
                            <input type="text" className="number-input" value={cardNumber} onChange={handleCardNumberChange} placeholder="• • • •  • • • •  • • • •  • • • •" maxLength="19" />
                            <img className='card-icon' src={cardBrandToIcon[card.brand]} alt="Card brand icon" />
                        </div>
                    </div>
                    <div className='grid-block'>
                        <div className='holder gb1'>
                            <label className='label'>Expiry Date:</label>
                            <div className='cardExpiry'>
                                <p className='invalid-tag' style={{display:(expiryTag==true)?'block':'none'}}>invalid</p>
                                <input type="text" className="expiry-input" value={expiryDate} onChange={handleExpiryDateChange} placeholder="MM/YY" maxLength="5" />
                            </div>
                        </div>
                        <div className='holder gb2'>
                            <label className='label'>CVC:</label>
                            <div className='cardCVC'>
                                <p className='invalid-tag' style={{display:(cvvTag==true)?'block':'none'}}>invalid</p>
                                <input type="text" className="cvv-input" value={cvv} onChange={handleCvvChange} placeholder="CVV" maxLength="3" />
                            </div>
                        </div>
                    </div>
                    <div className='holder'>
                        <label className='label'>Card Holder:</label>
                        <div className='cardHolder'>
                            <p className='invalid-tag' style={{display:(holderTag==true)?'block':'none'}}>invalid</p>
                            <input type="text" className="holder-input" value={cardHolder} onChange={handleHolderChange} placeholder="eg. Jordan Martinson" />
                        </div>
                    </div>
                </>
                <button className='cardButton' type="submit"  onClick={(lock==false)?handleSubmit:null}>Add Card</button>
            </div>
        </div>
    );

    return output;
}
